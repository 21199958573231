body {
    margin: 0px !important;
}

.background {
    background: url("../../../static/images/background-auth.png");
    position: absolute;
    background-position: center center;
    height: 100%;
    width: 100%;
    top: 0;
}
