@font-face {
    font-family: "Poppins";
    src: local("Poppins"), url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

body {
    font-family: "Poppins", sans-serif !important;
    background-color: #f9f9f9;
    overflow: hidden;
}

main {
    overflow-y: auto;
    height: calc(100vh - 50px);
}

.Toastify__toast--success {
    background: #40a943 !important;
}


.ant-modal-header {
    border-bottom: 1px solid #1b2a40;
    padding-bottom: 10px;
}

.ant-modal-header .ant-modal-title {
    font-size: 18px;
    color: #1b2a40;
}

.ant-modal-content .ant-modal-close {
    color: rgb(255 255 255 / 45%);
}

.ant-modal-body {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
}

.btn-green.ant-btn-primary {
    background: #21a738 !important;
    border-color: #21a738 !important;
}

[id*="dashboards-popup"].ant-menu-vertical {
    position: relative;
    top: 90px;
}