.inputForm {
    margin-bottom: 17px;

    input,
    textarea {
        width: 100%;
    }
}

.activeItem {
    margin-top: 30px;
    margin-bottom: 10px;
    button {
        margin-top: -2px;
    }

    label {
        margin-left: 15px;
        font-size: 16px;
    }
}
