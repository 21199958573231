.groupInfo {
    display: grid;
    padding-bottom: 10px;

    &.email span,
    &.nome span {
        text-overflow: ellipsis;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
    }
}

.modal {
    margin-top: -100px;

    [class*="ant-modal-body"] {
        max-height: calc(100vh - 110px) !important;
    }
}

.compradores {
    display: flex;
    width: 100%;
    margin-bottom: 5px;

    .nome {
        width: 247px;
        padding-left: 3px;
    }

    .observacao {
        width: 457px;
    }

    :last-child {
        width: 20px;
        cursor: pointer;
    }
}
