.container {
    .toolBar {
        margin-bottom: 15px;
        float: left;
    }
    
    .qtdeRows {
        text-align: right;
        font-size: 16px;
        margin-top: 5px;
        margin-right: 10px;
        min-height: 42px;
    }
}
