.contentInput {
    font-family: "Poppins", sans-serif;
    color: #354558;

    .description {
        display: table;
        font-weight: 600;
        font-size: 13px;
    }

    .labelInput {
        position: relative;
        top: 5px;

        input {
            color: #495057;
            border: 1px solid #ced4da;
            border-radius: 4px;
            padding: 6px 12px;
            font-family: inherit;
            height: 35px;
            font-size: 13px;
            &:focus {
                outline-color: #30419b;
            }

            &::placeholder {
                color: #b5b5b5 !important;
            }

            &[readonly] {
                background-color: #f5f5f5;
                cursor: not-allowed;
            }
        }
    }

    .errorInput {
        font-size: 11px;
        color: #fc5454;
        margin-top: 3px;
        margin-left: 1px;
        font-weight: 100;
    }

    [class*="ant-input-number-borderless"] [class*="ant-input-number-handler-wrap"] {
        display: none;
    }
}
