.container {
    .toolBar {
        margin-bottom: 15px;
        float: left;

        button:not(last-child) {
            margin-right: 10px;
        }
    }

    .qtdeRows {
        text-align: right;
        font-size: 16px;
        margin-top: 5px;
        margin-right: 10px;
    }

    .tablePedidos {
        table {
            font-size: 13px !important;
        }

        .isImportedNow {
            float: left;
            width: 10px;
            background: green;
            border-radius: 5px;
            height: 10px;
            top: 6px;
            position: relative;
            left: 0px;
            margin-right: 10px;
        }

        .nomeArquivo {
            max-width: 250px;
        }
    }
}


.columnAction svg {
    font-size: 15px;
    color: gray;
    margin-left: 25px;
    cursor: pointer;
}

