.container {
    .inputFormSm {
        margin-bottom: 17px;
    }

    .inputForm {
        margin-bottom: 17px;

        input {
            width: 100%;
        }
    }

    .activeItem {
        margin-top: 30px;
        margin-bottom: 10px;
        button {
            margin-top: -2px;
        }

        label {
            margin-left: 15px;
            font-size: 16px;
        }
    }

    .groupField {
        display: flex;
        width: 100%;
    
        .inputGroup:not(:last-child) {
            margin-right: 10px;
            margin-bottom: 17px;
        }
    
        input {
            width: 100%;
        }
    
        .selectGroup {
            display: table-cell;
    
            &:not(:last-child) {
                padding-right: 10px;
            }
        }
    
        .buttonAddComprador {
            position: relative;
            top: 26px;
        }
    
        .buttonRemoveComprador {
            position: relative;
            top: 6px;
        }
    }
}
