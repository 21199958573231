.container {
    .toolBar {
        margin-bottom: 15px;
    }

    .form {
        margin-top: 10px;
        margin-bottom: 20px;

        .form-top {
            width: 1200px;
            display: flex;

            .select-lg {
                padding-right: 10px;
                width: 350px;
            }

            .select-sm {
                padding-right: 10px;
                width: 150px;
            }

            .button {
                position: relative;
                top: 20px;
            }
        }
    }

    .tableBonus {
        margin-top: 20px;

        table {
            font-size: 13px;

            input {
                border: 0px !important;
            }
        }
    }

    .tableMetas {
        margin-top: 50px;

        table {
            font-size: 13px;

            input {
                border: 0px !important;
            }
        }
    }

    .formData {
        .header {
            text-align: center;
        }

        .cliente {
            margin-top: 13px;
        }

        .info {
            font-weight: normal;
            text-align: center;
            line-height: 33px;
            background: #f5f5f5;
            border: 1px solid #cdcdcd;
            border-radius: 4px;
            margin-top: 4px;
            min-height: 35px;
            min-width: 138px;
        }
    }
}

.rowTotal {
    background: #f3ffeb;

    td:not(:first-child) {
        padding-left: 20px !important;
    }
}