.containerBar {
    height: calc(100vh - 64px);
    float: left;
    position: relative;
    width: 256px;
    padding-top: 10px;
    background: #001529;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px; /* Width of the vertical scrollbar */
    }
    
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Background color of the scrollbar track */
    }
    
    &::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar thumb */
        border-radius: 10px; /* Rounded corners for the scrollbar thumb */
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the scrollbar thumb on hover */
    }

    .header {
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #fff;
        text-align: center;

        img {
            width: 150px;
        }
    }

    .content {
        background-color: #1b2a40;
    }
}

.containerBarCollapsed {
    width: 80px !important;
}

.parentMenuCollapsed,
.parentMenuCollapsed.ant-menu-item-selected {
    border: 0 !important;
    margin: 0 !important;
    background: #001529 !important;
    color: #fff !important;
    cursor: default;

    &:hover {
        color: #fff;
    }
}
