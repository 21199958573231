.container {
    .form {
        .groupSelect {
            margin-bottom: 20px;

            button {
                font-family: "Poppins";
                position: relative;
                top: 26px;
                left: 10px;
            }

            .select {
                width: 400px;
                display: table-cell;

                &:not(:last-child) {
                    padding-right: 10px;
                    width: 410px;
                }
            }

            .upload {
                font-weight: 500;

                .selectFile {
                    font-family: "Poppins";
                    width: 400px;
                    font-size: 13px;
                    border: 1px solid #ced4da;
                    padding: 5px;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 4px;
                    color: #fff;
                    background-color: #40779e;
                }

                .fileSelected {
                    display: flex;
                    .fileName {
                        font-family: "Poppins";
                        width: 370px;
                        font-size: 13px;
                        border-top: 1px solid #ced4da;
                        border-bottom: 1px solid #ced4da;
                        border-left: 1px solid #ced4da;
                        padding: 5px;
                        text-align: center;
                        color: #fff;
                        background-color: green;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    .clearFile {
                        font-family: "Poppins";
                        width: 30px;
                        font-size: 13px;
                        border-top: 1px solid #ced4da;
                        border-bottom: 1px solid #ced4da;
                        border-right: 1px solid #ced4da;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        background: #c14444;
                        color: #fff;
                        text-align: center;
                        padding-top: 5px;
                        cursor: pointer;
                    }
                }
            }

            .buttonDesconto {
                margin-top: 20px;
                margin-bottom: 10px;
                margin-right: 13px;
                margin-left: -10px;
                button {
                    margin-top: -55px;
                }

                label {
                    margin-left: 15px;
                    font-family: "Poppins";
                    font-size: 13px;
                }
            }

            .campoDescontos {
                width: 92px;
                margin-left: 5px;
            }

            .campoDescontoTotal {
                width: 118px;
                margin-left: 10px;
            }
        }
    }

    .infoImportation {
        span {
            font-weight: 600;
        }
    }
}
