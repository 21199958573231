.container {
    .toolBar {
        margin-bottom: 15px;
        float: left;

        button:not(last-child) {
            margin-right: 10px;
        }
    }

    .qtdeRows {
        text-align: right;
        font-size: 16px;
        margin-top: 5px;
        margin-right: 10px;
    }
}

.columnAction svg {
    font-size: 15px;
    color: gray;
    margin-left: 15px;
    cursor: pointer;
}
