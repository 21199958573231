.container {
    .toolBar {
        margin-bottom: 15px;
    }

    .projecao-1 {
        background-color: black;
        border-right: 1px solid #f5f5f5;
    }

    .projecao-0 {
        color: #fff;
        border-right: 1px solid #f5f5f5;
    }

    [class="ant-table-cell"],
    [class="ant-table-cell ant-table-cell-row-hover"],
    [class="ant-table-cell ant-table-column-sort"],
    [class="ant-table-cell ant-table-column-sort ant-table-cell-row-hover"] {
        padding: 0 !important;
    }

    .projecao-status {
        text-align: center;
        cursor: pointer;
    }

    [class*="editing"] {
        background-color: #dbffdb;
    }
}

.columnAction svg {
    font-size: 15px;
    color: gray;
    margin-left: 25px;
    cursor: pointer;
}
