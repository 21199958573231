.container {
    .form {
        .groupSelect {
            margin-bottom: 20px;

            button {
                font-family: "Poppins";
                position: relative;
                top: 26px;
                left: 10px;
            }

            .select {
                width: 400px;
                display: table-cell;

                &:first-child {
                    padding-right: 10px;
                    width: 410px;
                }

                .teste {
                    color: red;
                }
            }

            .upload {
                margin-top: 25px;
                font-weight: 500;

                .selectFile {
                    padding-top: 7px !important;
                    height: 35px;
                    font-family: "Poppins";
                    width: 400px;
                    font-size: 13px;
                    border: 1px solid #ced4da;
                    padding: 5px;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 4px;
                    color: #fff;
                    background-color: #40779e;
                }

                .fileSelected {
                    display: flex;
                    height: 35px;
                    .fileName {
                        padding-top: 7px !important;
                        font-family: "Poppins";
                        width: 370px;
                        font-size: 13px;
                        border-top: 1px solid #ced4da;
                        border-bottom: 1px solid #ced4da;
                        border-left: 1px solid #ced4da;
                        padding: 5px;
                        text-align: center;
                        color: #fff;
                        background-color: green;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    .clearFile {
                        padding-top: 7px !important;
                        font-family: "Poppins";
                        width: 30px;
                        font-size: 13px;
                        border-top: 1px solid #ced4da;
                        border-bottom: 1px solid #ced4da;
                        border-right: 1px solid #ced4da;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        background: #c14444;
                        color: #fff;
                        text-align: center;
                        padding-top: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .infoUpdating {
        span {
            font-weight: 600;
        }
    }
}
