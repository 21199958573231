.container {
    .toolBar {
        margin-bottom: 15px;
    }

    .config {
        margin-bottom: 70px;
        .configItem {
            margin-bottom: 10px;
            button {
                margin-top: -2px;
            }

            label {
                margin-left: 15px;
                font-size: 16px;
            }
        }
    }
}
