.container {
    .toolBar {
        margin-bottom: 15px;
    }

    .form {
        margin-top: 10px;

        .form-top {
            width: 1200px;
            display: flex;

            .select-lg {
                padding-right: 10px;
                width: 350px;
            }

            .select-sm {
                padding-right: 10px;
                width: 150px;
            }

            .button {
                position: relative;
                top: 20px;
            }
        }
    }

    .tableBonus {
        margin-top: 20px;

        table {
            font-size: 13px;

            input {
                border: 0px !important;
            }
        }
    }

    .tableMetas {
        margin-top: 50px;

        table {
            font-size: 13px;

            input {
                border: 0px !important;
            }
        }
    }
}

.rowTotal {
    background: #f3ffeb;

    td:not(:first-child) {
        padding-left: 20px !important;
    }
}
