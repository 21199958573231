.container {
    .toolBar {
        margin-bottom: 15px;
    }
}

.columnAction svg {
    font-size: 15px;
    color: gray;
    margin-left: 25px;
    cursor: pointer;
}