.container {
    .inputFormSm {
        margin-bottom: 17px;
    }

    .inputForm {
        margin-bottom: 17px;

        input {
            width: 100%;
        }
    }

    .activeItem {
        margin-top: 30px;
        margin-bottom: 10px;
        button {
            margin-top: -2px;
        }

        label {
            margin-left: 15px;
            font-size: 16px;
        }
    }

    .avatarInput {
        position: relative;
        top: -11px;
        left: 220px;
        background-image: url("../../../../static/images/avatar-icon-70.jpg");
        height: 70px;
        width: 70px;
        cursor: pointer;

        img {
            width: 100%;
            border-radius: 100px;
        }
    }
}
