.container {
    .toolBar {
        margin-bottom: 15px;
    }

    .form {
        margin-top: 10px;

        .form-top {
            width: 1300px;
            display: flex;

            .select-lg {
                padding-right: 10px;
                width: 350px;
            }

            .select-sm {
                padding-right: 10px;
                width: 150px;
            }

            .button {
                position: relative;
                top: 20px;
            }
        }
    }

    .data {
        margin-top: 50px;
        // width: 1400px;

        .header {
            font-weight: bold;
            text-align: center;
            vertical-align: middle;
        }

        .rows {
            .mes {
                text-align: center;
                padding-top: 8px;
                letter-spacing: 1px;

                label {
                    color: gray;
                    font-size: 13px;
                }
            }

            input {
                width: 120px;
            }

            .info {
                font-weight: normal;
                text-align: center;
                line-height: 33px;
                background: #f5f5f5;
                border: 1px solid #cdcdcd;
                border-radius: 4px;
                margin-top: 4px;
                min-height: 35px;
                min-width: 138px;
            }
        }
    }
}
