.groupField {
    display: flex;
    width: 100%;

    .inputGroup:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 17px;
    }

    input {
        width: 100%;
    }

    .selectGroup {
        display: table-cell;

        &:not(:last-child) {
            padding-right: 10px;
        }
    }

    .buttonAddComprador {
        position: relative;
        top: 26px;
    }

    .buttonRemoveComprador {
        position: relative;
        top: 6px;
    }
}

.compradores {
    display: flex;
    width: 100%;
    margin-bottom: 5px;

    .nome {
        width: 247px;
        padding-left: 3px;
    }

    .observacao {
        width: 457px;
    }

    :last-child {
        width: 20px;
        cursor: pointer;
    }
}

.inputForm {
    margin-bottom: 17px;

    input,
    textarea {
        width: 100%;
    }
}

.selectForm {
    display: table-cell;
    width: 100%;
}

.campoDescontos {
    width: 92px;
}

.campoDescontos:not(:first-child) {
    margin-left: 5px;
}

.campoDescontoTotal {
    width: 100px;
    margin-left: 10px;
}